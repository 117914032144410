<template>

  <a @click="sendMetric('click_phone')" class="" :href="'tel:' + phone_raw">{{phone}}</a>

</template>


<script setup>
  import CONSTANTS from '../common/constants.js'
  import sendMetric from '../common/sendMetric.js'

  const {phone, phone_raw} = CONSTANTS

</script>


<style lang="scss" scoped>


</style>