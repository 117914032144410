<template>
  <section id="tranid-in" class="tranidin section">
    <div class="tranidin__inner section__inner">
      <div class="tranidin__content section__content">
        <h2 class="tranidin__title section__title">
          Введите данные вашего авто <br />
          и узнайте выгоду по Trade-in на HYUNDAI
        </h2>
        <div @click="select_acive = !select_acive" class="tranidin__select select">
          <div  class="select__value">
            <p class="select__value-item"> {{selected_value ? selected_value : "Выберите модель"}}</p>
          </div>
          <ul :class="select_acive ? 'select__list--active' :''" class="select__list">
            <li v-for="select in  select_values" :key="select" @click.stop="newSelectValue(select)" class="select__list-item">{{select}}</li>
          </ul>
        </div>
        <callback-input
          @callBack="callBack"
					:form_class="'popup'"
					:focusOnMount="false"
					:data="{form:'traid-in',type:'sale',}"
				>
					{{data?.button ? data?.button : 'Получить выгоду'}}
				</callback-input>
      </div>
      <div class="tranidin__img section__img">
        <div class="gradient"></div>
        <img src="../images/bacground/traid-in.jpg" alt="" />
      </div>
    </div>
  </section>
</template>

<script>
import CallbackInput from "./callback-form/callback-input.vue";
export default {
  props:['data'],
  components: { CallbackInput },
  data(){
    return{
      select_acive:false,
      selected_value:'',
      select_values:['Elantra New','Santa Fe New','Sonata','H-1'],//'Solaris','Creta',
    }
  },
  methods:{
    newSelectValue(val){
      this.select_acive = false
      this.selected_value = val
    },
    callBack(val){
      this.$emit('callBack',Object.assign(val,{model:this.selected_value}))
    },
  }
}
</script>

<style lang="sass" scoped>
.tranidin
    margin-bottom: 70px
    &__content
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        flex: 1 1 auto
    &__title
        margin-bottom: 61px
    &__select
      margin-bottom: 10px
    &__img
      position: relative
    


.select
  position: relative
  cursor: pointer
  max-width: 360px
  &::after
    position: absolute
    right: 0px
    top: 30%
    content: ""
    width: 53px
    z-index: 2
    height: 30px
    background: url(../images/utils/select.png) center no-repeat
  &:before
      position: absolute
      right: 0px
      content: ""
      z-index: 1
      top: 0
      bottom: 0
      width: 53px
      background-color: #E4DCD3
  &__value
    border: 1px solid #E4DCD3
    max-width: 360px
    width: 360px
    text-align: center
    padding: 17px 0px
  &__list
    position: absolute
    padding: 0px
    list-style-type: none
    z-index: 3
    top: 61px
    left: 0
    right: 0
    background-color: #fff
    overflow: hidden
    max-height: 0px
    transition: all 0.8s
    opacity: 0
    &--active
      max-height: 300px
      overflow: auto
      opacity: 1
    &-item
      padding: 9px 0px 9px 19px
      border: 1px solid rgba(228, 220, 211, 1)
      width: 100%
      transition: all 0.2s
      font-size: 14px
      &:hover
        background-color: #E4DCD3



@media screen and (max-width: 1620px)
    .tranidin__content
        padding-left: 25px
@media screen and (max-width: 1174px)
  .tranidin__content
    padding: 13px 5px 13px 25px
@media screen and (max-width: 940px)
    .tranidin__content
      justify-content: flex-start
      align-items: flex-start
    .tranidin__select
      margin-top: 61px
    .tranidin__inner
        flex-direction: column
    .tranidin__content
        order: 2
        position: relative
        align-items: center
    .tranidin__title
        position: absolute
        top: -120px
        color: #fff
        left: 0px
        right: 0px
        z-index: 3
        padding-left: 15px
        text-align: center
@media screen and (max-width: 540px)
    .select__value
      width: auto
    .tranidin__title
        font-size: 18px
        line-height: 22px
        top: -75px
    .tranidin__content
      padding: 0 10px
    .tranidin__select
      width: 300px


</style>
