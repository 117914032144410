<template>
  <div class="info">

    <car-header :data="headerData" class="info__header" />

    <ul class="info__bonuses">
      <li
          v-for="(bonus, i) of bonuses" :key="i"
          class="info__bonuses-item"
      >
        <span class="info__bonuses-circle">
          <img :src="getIcon(bonus.icon)" alt="" class="info__bonuses-icon">
        </span>

        <p class="info__bonuses-text" v-html="bonus.text"></p>
      </li>
    </ul>

    <div class="info__btns">
      <button
          @click="emit('getCall')"
          class="info__btns-item button button-dark-blue"
      >
        УЗНАТЬ ЦЕНУ ПО АКЦИИ
      </button>
      <a
          href="#stock"
          class="info__btns-item button info__btns-item--white"
      >
        Комплектации
      </a>
    </div>

  </div>
</template>


<script setup>
  import CarHeader from './car-header.vue'

  const props = defineProps(['data'])
  const emit = defineEmits(['getCall'])

  const bonuses = [
    {
      text: `Бонус за трейд-ин ${props.data.trade_in ? '<b>' + props.data.trade_in + ' ₽</b>' : ''}`,
      icon: 'trade-in.svg'
    },
    {
      text: `Выгода до <b>${props.data.advantage} ₽</b>`,
      icon: 'price.svg'
    },
    {
      text: `<b>Охранный комплекс</b> в комплекте`,
      icon: 'warranty.svg'
    },
    {
      text: `<b>Постановка на учёт</b> в ГИБДД в подарок`,
      icon: 'register.svg'
    },
  ]

  const headerData = {
    title: props.data.stock_name,
    advantage: props.data.advantage,
    availability: props.data.availability,
  }

  const getIcon = icon => require('@/images/icons/' + icon)
</script>


<style lang="scss" scoped>
  @use '@/styles/constants.scss' as *;

  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #111;
    background-color: #ffffff;
    padding: 25px;
    padding-right: 60px;

    &__bonuses {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      list-style-type: none;
      font-size: 18px;
      margin: 1em 0;
      margin-bottom: 0;

      &-item {
        display: flex;
        align-items: center;
        width: 48%;
        font-size: 1em;
        margin-bottom: 1em;
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 0.7em;
      }
      
      &-circle {
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.2em;
        height: 2.2em;
        border-radius: 50%;
        background-color: #fff;
        margin-right: 1em;
      }

      &-icon {
        height: 1.3em;
      }

      &-text {
        font-size: 1em;

        :deep(b) {
          color: $blue;
          white-space: nowrap;
        }
      }
    }

    &__btns {

      &-item {
        display: block;
        width: 100%;
        font-size: 18px;
        font-weight: normal;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        border: 1px solid $blue;
        margin-top: 1em;

        &--white {
          color: $blue;
          background-color: transparent;
        }
      }
    }
  }

  @media (max-width: 1236px) {
    .info {
      &__bonuses {
        margin: 0.5em 0;

        &-item {
          font-size: 15px;
        }
      }

      &__btns {
        gap: 10px;

        &-item {
          font-size: 15px;
        }
      }
    }
  }

  @media (max-width: 1000px) {
    .info {
      padding: 15px 20px;

      &__bonuses {
        margin-bottom: 0.1em;

        &-item {
          width: 100%;
          font-size: 16px;
          margin-bottom: 0.3em;
        }
      }

      &__btns {
        gap: 0px;
        flex-wrap: wrap;

        &-item {
          width: 100%;
          margin-top: 0.5em;
        }
      }
    }
  }

  @media (max-width: 740px) {
    .info {

      &__header {
        display: none;
      }

      &__bonuses {
        &-item {
          font-size: 14px;
          margin-bottom: 1em;
        }
      }

      &__btns {
        &-item {
          font-size: 14px;
        }
      }
    }
  }

</style>