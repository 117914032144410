<template>
  <div class="car">

    <car-header :data="headerData" class="car__header" />

    <div class="car__wrapper">
      <car-info
          :data="carItem"
          @getCall="getCall({type: 'sale', form:'car'})"
          class="car__info"
      />

      <car-slider :imgs="carItem.gallery" class="car__slider" />
    </div>

  </div>
</template>


<script>
  import CarHeader from './car-header.vue'
  import CarInfo from "./car-info.vue";
  import CarSlider from "./car-slider.vue"
  import Mixins from '../../../common/mixin';

  export default {
    props: ["carItem"],
    mixins: [Mixins],
    components: {
      CarHeader, CarInfo, CarSlider
    },
    data() {
      return {
        headerData: {
          title: this.carItem.stock_name,
          advantage: this.carItem.advantage,
          availability: this.carItem.availability,
        }
      };
    },
  }
</script>


<style lang="scss" scoped>
  @use '@/styles/constants.scss' as *;

  .car {

    &__header {
      display: none;
    }

    &__wrapper {
      display: flex;
    }

    &__info {
      width: 55%;
      background-color: #F6F6F6;
      position: relative;
    }

    &__slider {
      width: 45%;
      max-width: 600px;
      min-width: 0;
      min-height: 100%;
      height: 390px;
    }
  }

  @media (max-width: 1400px) {
    .car {
      &__slider {
        height: auto;
      }
    }
  }

  @media (max-width: 1000px) {
    .car {
      &__info {
        width: 300px;
      }

      &__slider {
        flex-grow: 1;
      }
    }
  }

  @media (max-width: 740px) {
    .car {
      background-color: #F6F6F6;
      margin: 20px 10px;
      padding: 20px 0;

      &__header {
        display: flex;
      }

      &__wrapper {
        flex-direction: column;
      }

      &__info {
        width: 100%;
        order: 2;
      }

      &__slider {
        width: 100%;
        max-width: none;
        height: 60vw;
      }
    }
  }

</style>