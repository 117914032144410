import { createApp } from "vue";
import Constants from "./common/constants";
import "./styles/main.scss";
// import 'lazysizes';

import App from "./App.vue";

let app = createApp(App);

app.config.globalProperties.CONSTANTS = Constants;
app.mount("#app");
