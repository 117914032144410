<template>
  <section class="advantages">
    <div class="container">
      <div class="advantages__inner">
        <h2 class="advantages__title">ПРЕИМУЩЕСТВА ПОКУПКИ В РОЛЬФ</h2>
        <div class="advantages__content">
          <ul class="advantages__list">
            <li  v-for="item in  advantages_items" :key="item.text" class="advantages__list-item">
              <div class="advantages__list-item-inner">
                <img
                @click="getCall({ type: 'sale', form: 'advantages' })"
                v-if="item.cursor"
                  class="advantages__list-cursor"
                  src="../images/icons/cursor.png"
                  alt=""
                />
                <img
                @click="getCall({ type: 'sale', form: 'advantages' })"
                  class="advantages__list-img"
                  :src="loadAdvantagesImg(item.img)"
                  alt=""
                />
              </div>
              <p @click="getCall({ type: 'sale', form: 'advantages' })" v-html="item.text" class="advantages__list-text"></p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Mixin from "../common/mixin"
export default {
  mixins:[Mixin],
  data() {
    return {
      advantages_items: [
        {
          text: "Автомобили <br /> в наличии с ПТС",
          img:'sheet',
          cursor:false,
        },
        {
          text: "Выгодный <br /> и быстрый Trade-in",
          img:'circle',
          cursor:false,
        },
        // {
        //   text: "Рассрочка 0%",
        //   img:'watch',
        //   cursor:false,
        // },

        {
          text: "Большой выбор <br /> HYUNDAI на тест-драйв",
          img:'flag',
          cursor:false,
        },
        {
          text: "Гарантия <br />официального дилера",
          img:'briefcase',
          cursor:false,
        },
        {
          text: "Покупка <br />одним днём",
          img:'time',
          cursor:true,
        },
      ],
    };
  },
  methods:{
      loadAdvantagesImg(name){
          return require(`../images/icons/${name}.png`)
      },
  },
};
</script>

<style lang="sass" scoped>
.advantages
    margin-top: 70px
    margin-bottom: 70px
    &__inner
        display: flex
        flex-direction: column
    &__title
        font-size: 36px
        font-weight: 700
        line-height: 44px
        text-align: center
    &__list
        margin: 0 auto
        padding: 0px
        display: flex
        list-style-type: none
        flex-wrap: wrap
        align-items: center
        justify-content: center
        &-img
          cursor: pointer
        &-cursor
            position: absolute
            bottom: -15px
            cursor: pointer
            right: -8px
        &-text
            font-size: 22px
            font-weight: 400
            line-height: 27px
            text-align: center
            margin-top: 24px
            cursor: pointer
            font-family: 'Hyundai Sans Text'
        &-item
            flex: 1 1 33.3333333%
            margin-top: 95px
            display: flex
            flex-direction: column
            position: relative
            align-items: center
            &-inner
                position: relative
@media screen and (max-width: 938px)
    .advantages__inner
        justify-content: center
        align-items: center
    .advantages__list-item
        flex: 1 1 50%
@media screen and (max-width: 517px)
    .advantages__list-text
        font-size: 18px
        line-height: 22px
    .advantages__list-item
        margin-top: 45px
    .advantages__list-img
        height: 50px
    .advantages__list
        align-items: flex-start
    .advantages__title
        line-height: 29px
        font-size: 24px
        text-align: center
        font-weight: 700
        font-family: 'Hyundai Sans Head'
</style>
