import CF from "./common-functions";
import models from "../common/cars-info"
const PHONE = '+7 (495) 788-48-88'.replace(/-/g, '\u2011');

const PHONE_RAW = PHONE.replace(/\D+/g, "");
let start = new Date(2024, 4, 16, 23, 0, 0);
let next = CF.getNextDate(start);

function loadMapIcon(number) {
    return require(`../images/map${number}.png`)
}

export default {
    phone: PHONE,
    phone_raw: PHONE_RAW,
    worktime: 'Ежедневно с 8:00 до 22:00',
    offices: [{
        id: 0,
        name: 'Химки',
        address: 'г. Химки, Ленинградское шоссе, вл. 21',
        x_coords: 37.412631661376864,
        y_coords: 55.90576102855642,
        icon: loadMapIcon('1')
    }, ],
    coords: {
        x_center: 37.3900,
        y_center: 55.90591019272274,
        x_mobile: 37.413,
        y_mobile: 55.90591019272274,
    },
    need_agreement: true,
    cKeeper: undefined,
    models: models.CARS.map(el => el.folder_name),
    start_date: start,
    end_date: next,
}