<template>
  <section class="block-stock">
    <div class="container">
      <h2 class="title">Комплектации и цены HYUNDAI</h2>
      <selector
        v-if="device_width > 800"
        :data="available_models"
        :value="cur.model"
        :alias="cur.model"
        @change="setFilter('model', $event)"
      ></selector>
      <div class="block-stock__filters">
        <select
          class="block-stock__filter"
          v-for="filter in filters"
          :key="filter.alias"
          :name="filter.alias"
          :value="cur[filter.alias]"
          @change="setFilter(filter.alias, $event.target.value)"
        >
          <option :value="filter.default_value">
            {{ filter.placeholder }}
          </option>
          <option :value="item" v-for="item in filter.data" :key="item">
            {{ item }}
          </option>
        </select>
      </div>
      <stock-table
        v-if="device_width > 800"
        :data="filtered_items"
        @call="getCall"
      ></stock-table>
      <stock-slider
        v-else
        :cars_data="filtered_items"
        @call="getCall"
      ></stock-slider>
    </div>
  </section>
</template>

<script>
import Mixin from "../common/mixin";
import COMPLECTATIONS from "../common/complectations";

import StockTable from "./stock-table.vue";
import StockSlider from "./stock-slider.vue";
import Selector from "./selector.vue";
const all_item = "Все";

export default {
  name: "block-stock",
  props: ["stock_active"],
  mixins: [Mixin],
  components: { StockTable, Selector, StockSlider },
  data() {
    return {
      cars: COMPLECTATIONS,
      expanded: null,
      cur: {
        model: "Santa Fe",
        compl: "Все",
        engine: "Все",
      },
      sortingModels: [
        "Santa Fe",
        "Tucson",
        "Palisade",
        //"Mufasa",
        "Staria",
        //"Sonata",
        "Elantra NEW",
      ],
      sortingCompl: {
        "Palisade": ["Family", "Luxe", "Calligraphy", "Black Edition"],
        //"Mufasa": ["DLX", "LUX"],
        Tucson: ["Comfort", "Travel", "High-tech", "Luxe"],
        "Santa Fe": ["Travel", "Prime", "High-tech", "Luxe"],
        Staria: ["Prestige", "Premium"],
        //Sonata: ["Comfort", "Style", "Business", "Prestige+PANO"],
        "Elantra NEW": ["Luxe", "Exclusive Edition", "Elegance", "Prestige", "Active"]
      },
      carColors: {
        "Palisade": ["black", "brown", "forest", "silver", "white"],
        //"Mufasa": ["silver", "black"],
        Tucson: ["black", "blue", "brown", "red", "silver", "white"],
        "Santa Fe": ["black", "blue", "brown", "white"],
        Staria: ["blue"],
        "Elantra NEW": [
          "black",
          // "blue",
          "amazon_grey",
          // "cyber_grey",
          // "orange",
          // "red",
          // "white",
        ],
       // Sonata: ["black", "blue", "red", "white"],
      },
    };
  },
  watch: {
    stock_active() {
      this.cur.model = this.stock_active;
      this.cur.compl = "Все";
      this.cur.engine = "Все";
    },
  },
  computed: {
    filters() {
      let ret = [
        {
          data: this.available_compls,
          placeholder: "Выберите комплектацию",
          default_value: all_item,
          alias: "compl",
        },
        {
          placeholder: "Выберите двигатель",
          data: this.available_engines,
          default_value: all_item,
          alias: "engine",
        },
      ];

      if (this.device_platform !== "desktop") {
        ret.unshift({
          data: this.available_models,
          placeholder: "Выберите модель",
          default_value: all_item,
          alias: "model",
        });
      }
      return ret;
    },
    available_models() {
      const origin = Array.from(new Set(this.cars.map((car) => car.name)));
      return this.sortByAnother(origin, this.sortingModels);
    },
    available_compls() {
      const cur_model = this.cur.model === "Все" ? null : this.cur.model;
      const result = this.cars
        .filter((car) => !cur_model || car.name === cur_model)
        .map((car) => car.compl);
      let result_set = Array.from(new Set(result));
      return this.sortByAnother(result_set, this.sortingCompl[cur_model]);
    },
    available_engines() {
      const cur_model = this.cur.model === "Все" ? null : this.cur.model;
      const cur_compl = this.cur.compl === "Все" ? null : this.cur.compl;

      const result = this.cars
        .filter(
          (car) =>
            (!cur_model || car.name === cur_model) &&
            (!cur_compl || car.compl === cur_compl)
        )
        .map((car) => car.engine);
      const result_set = Array.from(new Set(result));
      return result_set;
    },
    filtered_items() {
      const complects = this.available_compls;
      const cur_model = this.cur.model === "Все" ? null : this.cur.model;
      const cur_compl = this.cur.compl === "Все" ? null : this.cur.compl;
      const cur_engine = this.cur.engine === "Все" ? null : this.cur.engine;

      const result = this.cars
        .filter(
          (car) =>
            (!cur_model || car.name === cur_model) &&
            (!cur_compl || car.compl === cur_compl) &&
            (!cur_engine || car.engine === cur_engine)
            // && car.price
        )
        .map((car) => {
          car.name = car.name.trim();
          car.compl = car.compl.trim();

          let carName = car.name.split(" ").join("_").toLowerCase();
          
          let carId = `${car.name.split(" ").join("_").toLowerCase()}_
                      ${car.compl.split(" ").join("_").toLowerCase()}_
                      ${car.engine.split(" ").join("_").toLowerCase()}`;

          const colors = this.carColors[car.name];
          const colorIndex = Math.floor(Math.random() * colors.length);
          if (car.compl === 'Black Edition') {
            car.color = 'black';
          } else {
            car.color = colors[colorIndex];
          }

          car.img = require("../images/compls/" +
            carName +
            "/" +
            car.color +
            ".png");

          car.id = carId;

          // car.price = car.price.split(",").join(" ");
          // car.price = car.price.split(".00").join("");
          // car.price = !Number.isInteger(car.price)
          //   ? Number(car.price.match(/\d/g).slice(0, -2).join(""))
          //   : car.price;
          return car;
        });

      return this.sortByAnotherWithKey(result, complects);
    },
  },
  methods: {
    sortByAnother(origin, target) {
      const out = target.reduce(
        (acc, compl, i) => ({
          ...acc,
          [compl]: { index: i, items: [] },
        }),
        {}
      );

      for (let i = 0; i < origin.length; ++i) {
        const item = origin[i];

        if (out[item]) {
          out[item].items.push(item);
          out[item].items.sort();
        } else {
          // const matchCompl = Object.keys(out).reduce((acc, key) => {
          //   const match = item.match(new RegExp(`^${key}`));
          //   if (match && match[0].length > acc.length) return key;
          //   return acc;
          // }, "");

          // if (matchCompl) {
          //   out[matchCompl].items.push(item);
          //   out[matchCompl].items.sort();
          // } else {
          //   const nextIndex = Object.keys(out).length + 1;
          //   out[item] = { index: nextIndex, items: [item] };
          // }
        }
      }

      const outComplete = Object.values(out)
        .map((group) => group.items)
        .flat();

      return outComplete;
    },
    sortByAnotherWithKey(origin, order) {
      const out = order.reduce((acc, compl) => ({ ...acc, [compl]: [] }), {});
      console.log(out);
      

      for (const item of origin) {
        out[item.compl].push(item);
      }

      return Object.values(out).flat();
    },
    setFilter(alias, item) {
      this.cur[alias] = item;

      const avails = {
        model: this.available_models,
        compl: this.available_compls,
        engine: this.available_engines,
      };
      const params = ["model", "compl", "engine"];
      const idx = params.indexOf(alias);

      params.slice(idx + 1).forEach((key) => {
        if (avails[key].length === 1) {
          this.cur[key] = avails[key][0];
        } else {
          this.cur[key] = "Все";
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/constants.scss";

.block-stock {
  padding-bottom: 100px;

  .title {
    margin-bottom: 50px;
    text-align: center;
    font-size: 35px;
    text-transform: uppercase;
  }

  &__filters {
    display: flex;
    align-items: center;
    background-color: #e6e3e3;
    padding: 15px 30px;
    border-radius: 0px 0 5px 5px;
  }

  &__filter {
    width: 100%;
    max-width: 291px;
    height: 48px;
    padding-left: 25px;
    text-align: start;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    border: 1px solid #000;
    cursor: pointer;
    background: url("../images/utils/arrow-down-new.svg") 93% 50% no-repeat;
    appearance: none;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

@media (max-width: 1024px) {
  .block-stock {
    &__filters {
      padding: 0;
    }

    &__filter {
      font-size: 12px;
      padding-left: 15px;
    }
  }
}
@media (max-width: 767px) {
  .block-stock {
    padding-bottom: 50px;

    .container {
      padding: 0 15px;
    }

    &__filters {
      background-color: transparent;
      flex-direction: column;
    }

    &__filter {
      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: 517px) {
  .block-stock {
    margin-bottom: 70px;
    padding-bottom: 0;

    .title {
      font-size: 24px;
      font-weight: bold;
    }
  }
}
</style>
