// Отправка данных в яндекс-метрику
export default function(goal){
	if(window.ym){
		// отправка только 1 раз за ВИЗИТ
		if(!sessionStorage.getItem(goal)) {
			ym(57632524, 'reachGoal', goal)
			// запрет на отправку в течении сессии
			sessionStorage.setItem(goal, 'true')
		}
	} else {
		console.log('Метрика не подключена!')
	}
}
