<template>
	<div class="block-thanks thanks modal">
		<div class="blacking" @click="hideSelf"></div>
		<div class="thanks__container">
			<img
				src="../images/utils/close.svg"
				alt="Закрыть"
				class="thanks__close"
				@click="hideSelf"
			/>
			<div class="thanks__content">
				<!-- <h3 class="thanks__title">Получить предложение</h3> -->
				<p class="thanks__subtitle">
					Спасибо! Ваша заявка успешно отправлена.
					<br />
					Наши менеджеры свяжутся с Вами в ближайшее время.
				</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "full-thanks",
	emits: ['close'],
	methods: {
		hideSelf() {
			this.$emit("close");
		},
	},
};
</script>

<style scoped lang="scss">
.thanks {
	&__container {
		position: fixed;
		z-index: 1200;
		top: 50vh;
		left: 50vw;
		transform: translate(-50%, -50%);
		width: 580px;
		background: #fff;
		color: #000;
		padding: 40px 20px 50px;
		cursor: default;
		text-align: center;
	}

	&__title {
		margin-bottom: 30px;
		text-align: center;
		font-weight: 400;
		letter-spacing: 0.01em;
		line-height: 1.2;
		font-size: 26px;
	}

	&__subtitle {
		font-size: 18px;
		max-width: 271px;
		margin: 0 auto;
	}

	&__close {
		position: absolute;
		right: 20px;
		top: 20px;
		width: 15px;
		height: 15px;
		cursor: pointer;
	}
}
</style>
