<template>
  <swiper
    :autoplay="{
      'delay': 3000
    }"
    :navigation="{
      'nextEl': '.special-slider__next',
      'prevEl': '.special-slider__prev',
    }"
    :loop="true"
    :lazy="{
      loadOnTransitionStart: true,
      loadPrevNext: true,
      loadPrevNextAmount: 1
    }"
    class="special-slider"
  >
    <div class="special-slider__prev special-slider__arrow swiper-button-prev"></div>
    <swiper-slide
      v-for="(img, i) in imgs" :key="i"
      class="special-slider__slide"
    >
      <img :data-src="img" alt="" class="special-slider__img swiper-lazy">
      <div class="swiper-lazy-preloader"></div>
    </swiper-slide>
    <div class="special-slider__next special-slider__arrow swiper-button-next"></div>
  </swiper>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Autoplay, Navigation, Lazy } from "swiper";
import 'swiper/swiper.scss';

SwiperCore.use([Autoplay, Navigation, Lazy]);

defineProps(['imgs']);
</script>

<style lang="scss" scoped>
.special-slider {
  margin: 0;
  height: 100%;

  &__arrow {
    background: none;

    &::after {
      color: #fff;
    }
  }

  &__next {
    &::after {
      content: "next";
    }
  }

  &__prev {
    &::after {
      content: "prev";
    }
  }

  &__slide{
    max-width: 100%;
  }

  &__img {
    width: 100%;
    height: 100%;
  }
}
</style>